@use "../../styles/assets" as *;

.contact {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  min-width: 100vw;
  min-height: 70vh;
  text-transform: capitalize;
}

.contactbx {
  width: 50rem;
  padding: 4rem 2rem;
  border-radius: 0.5rem;
  border: 0.5rem solid $background;
  box-shadow: 5px 5px 10px #b5b5b5, -5px -5px 10px #ffffff;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  overflow: hidden;
  background: url("../assets/illustration.webp") no-repeat $primary;
  // background-position: 125% center;
  background-position: 35% 35%;

  h3 {
    color: $primary;
    font-size: 1.5rem;
    font-weight: 700;
  }

  p {
    color: $primary;
    font-weight: 400;
    font-size: 0.8rem;
    max-width: 20rem;
  }

  button {
    background: $primary;
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    color: $background;
    text-transform: capitalize;
    font-weight: 500;
    width: fit-content;
  }
}

.iconbtn {
  a {
    display: flex;
    color: #ffffff;
    align-items: center;
    gap: 0.5rem;
  }
}

@media screen and (max-width: 500px) {
  .contactbx {
    width: 18rem;
    margin: 1rem;
    background-position: 10% center;

    h3 {
      font-size: 1.25rem;
    }
  }
}